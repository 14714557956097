// STUDENT LOGIN ACTIONS
export const STUDENT_LOGIN_REQUEST = 'STUDENT_LOGIN_REQUEST'
export const STUDENT_LOGIN_SUCCESS = 'STUDENT_LOGIN_SUCCESS'
export const STUDENT_LOGIN_ERROR = 'STUDENT_LOGIN_ERROR'
export const STUDENT_LOGOUT = 'STUDENT_LOGOUT'


// STUDENT REGISTER ACTIONS
export const STUDENT_REGISTER_REQUEST = 'STUDENT_REGISTER_REQUEST'
export const STUDENT_REGISTER_SUCCESS = 'STUDENT_REGISTER_SUCCESS'
export const STUDENT_REGISTER_ERROR = 'STUDENT_REGISTER_ERROR'


// STUDENT PROFILE ACTIONS
export const STUDENT_GET_PROFILE_REQUEST = 'STUDENT_GET_PROFILE_REQUEST'
export const STUDENT_GET_PROFILE_SUCCESS = 'STUDENT_GET_PROFILE_SUCCESS'
export const STUDENT_GET_PROFILE_ERROR = 'STUDENT_GET_PROFILE_ERROR'

export const STUDENT_CREATE_PROFILE_REQUEST = 'STUDENT_CREATE_PROFILE_REQUEST'
export const STUDENT_CREATE_PROFILE_SUCCESS = 'STUDENT_CREATE_PROFILE_SUCCESS'
export const STUDENT_CREATE_PROFILE_ERROR = 'STUDENT_CREATE_PROFILE_ERROR'



// STUDENT SCHOOL ACTIONS
export const GET_SCHOOL_RECEIPT_REQUEST = 'GET_SCHOOL_RECEIPT_REQUEST'
export const GET_SCHOOL_RECEIPT_SUCCESS = 'GET_SCHOOL_RECEIPT_SUCCESS'
export const GET_SCHOOL_RECEIPT_ERROR = 'GET_SCHOOL_RECEIPT_ERROR'

export const GET_SCHOOLPAYMENTINFO_REQUEST = 'GET_SCHOOLPAYMENTINFO_REQUEST'
export const GET_SCHOOLPAYMENTINFO_SUCCESS = 'GET_SCHOOLPAYMENTINFO_SUCCESS'
export const GET_SCHOOLPAYMENTINFO_ERROR = 'GET_SCHOOLPAYMENTINFO_ERROR'

export const PAY_SCHOOL_CHARGES_REQUEST = 'PAY_SCHOOL_CHARGES_REQUEST'
export const PAY_SCHOOL_CHARGES_SUCCESS = 'PAY_SCHOOL_CHARGES_SUCCESS'
export const PAY_SCHOOL_CHARGES_ERROR = 'PAY_SCHOOL_CHARGES_ERROR'


// STUDENT FACULTY ACTIONS
export const GET_FACULTY_RECEIPT_REQUEST = 'GET_FACULTY_RECEIPT_REQUEST'
export const GET_FACULTY_RECEIPT_SUCCESS = 'GET_FACULTY_RECEIPT_SUCCESS'
export const GET_FACULTY_RECEIPT_ERROR = 'GET_FACULTY_RECEIPT_ERROR'

export const GET_FACULTYPAYMENTINFO_REQUEST = 'GET_FACULTYPAYMENTINFO_REQUEST'
export const GET_FACULTYPAYMENTINFO_SUCCESS = 'GET_FACULTYPAYMENTINFO_SUCCESS'
export const GET_FACULTYPAYMENTINFO_ERROR = 'GET_FACULTYPAYMENTINFO_ERROR'

export const PAY_FACULTY_CHARGES_REQUEST = 'PAY_FACULTY_CHARGES_REQUEST'
export const PAY_FACULTY_CHARGES_SUCCESS = 'PAY_FACULTY_CHARGES_SUCCESS'
export const PAY_FACULTY_CHARGES_ERROR = 'PAY_FACULTY_CHARGES_ERROR'


// STUDENT DEPARTMENT ACTIONS
export const GET_DEPARTMENT_RECEIPT_REQUEST = 'GET_DEPARTMENT_RECEIPT_REQUEST'
export const GET_DEPARTMENT_RECEIPT_SUCCESS = 'GET_DEPARTMENT_RECEIPT_SUCCESS'
export const GET_DEPARTMENT_RECEIPT_ERROR = 'GET_DEPARTMENT_RECEIPT_ERROR'

export const GET_DEPARTMENTPAYMENTINFO_REQUEST = 'GET_DEPARTMENTPAYMENTINFO_REQUEST'
export const GET_DEPARTMENTPAYMENTINFO_SUCCESS = 'GET_DEPARTMENTPAYMENTINFO_SUCCESS'
export const GET_DEPARTMENTPAYMENTINFO_ERROR = 'GET_DEPARTMENTPAYMENTINFO_ERROR'

export const PAY_DEPARTMENT_CHARGES_REQUEST = 'PAY_DEPARTMENT_CHARGES_REQUEST'
export const PAY_DEPARTMENT_CHARGES_SUCCESS = 'PAY_DEPARTMENT_CHARGES_SUCCESS'
export const PAY_DEPARTMENT_CHARGES_ERROR = 'PAY_DEPARTMENT_CHARGES_ERROR'


// GET ALGO BALANCE
export const GET_ALGOBALANCE_REQUEST = 'GET_ALGOBALANCE_REQUEST'
export const GET_ALGOBALANCE_SUCCESS = 'GET_ALGOBALANCE_SUCCESS'
export const GET_ALGOBALANCE_ERROR = 'GET_ALGOBALANCE_ERROR'




