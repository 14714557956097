// FACULTY LOGIN ACTIONS
export const FACULTY_LOGIN_REQUEST = 'FACULTY_LOGIN_REQUEST'
export const FACULTY_LOGIN_SUCCESS = 'FACULTY_LOGIN_SUCCESS'
export const FACULTY_LOGIN_ERROR = 'FACULTY_LOGIN_ERROR'
export const FACULTY_LOGOUT = 'FACULTY_LOGOUT'


// FACULTY REGISTER ACTIONS
export const FACULTY_REGISTER_REQUEST = 'FACULTY_REGISTER_REQUEST'
export const FACULTY_REGISTER_SUCCESS = 'FACULTY_REGISTER_SUCCESS'
export const FACULTY_REGISTER_ERROR = 'FACULTY_REGISTER_ERROR'



// GET SCHOOL RECEIPTS
export const GET_SCHOOLRECEIPTS_REQUEST = 'GET_SCHOOLRECEIPTS_REQUEST'
export const GET_UNVERIFIEDSCHOOLRECEIPTS_SUCCESS = "GET_UNVERIFIEDSCHOOLRECEIPTS_SUCCESS"
export const GET_VERIFIEDSCHOOLRECEIPTS_SUCCESS = "GET_VERIFIEDSCHOOLRECEIPTS_SUCCESS"
export const GET_SCHOOLRECEIPTS_ERROR = 'GET_SCHOOLRECEIPTS_ERROR'

// GET SCHOOL RECEIPT
export const GET_SCHOOLRECEIPT_REQUEST = 'GET_SCHOOLRECEIPT_REQUEST'
export const GET_SCHOOLRECEIPT_SUCCESS = 'GET_SCHOOLRECEIPT_SUCCESS'
export const GET_SCHOOLRECEIPT_ERROR = 'GET_SCHOOLRECEIPT_ERROR'

// VERIFY SCHOOL RECEIPT
export const VERIFY_SCHOOLRECEIPT_REQUEST = 'VERIFY_SCHOOLRECEIPT_REQUEST'
export const VERIFY_SCHOOLRECEIPT_SUCCESS = 'VERIFY_SCHOOLRECEIPT_SUCCESS'
export const VERIFY_SCHOOLRECEIPT_ERROR = 'VERIFY_SCHOOLRECEIPT_ERROR'



// GET FACULTY RECEIPTS
export const GET_FACULTYRECEIPTS_REQUEST = 'GET_FACULTYRECEIPTS_REQUEST'
export const GET_UNVERIFIEDFACULTYRECEIPTS_SUCCESS = "GET_UNVERIFIEDFACULTYRECEIPTS_SUCCESS"
export const GET_VERIFIEDFACULTYRECEIPTS_SUCCESS = "GET_VERIFIEDFACULTYRECEIPTS_SUCCESS"
export const GET_FACULTYRECEIPTS_ERROR = 'GET_FACULTYRECEIPTS_ERROR'

// GET FACULTY RECEIPT
export const GET_FACULTYRECEIPT_REQUEST = 'GET_FACULTYRECEIPT_REQUEST'
export const GET_FACULTYRECEIPT_SUCCESS = 'GET_FACULTYRECEIPT_SUCCESS'
export const GET_FACULTYRECEIPT_ERROR = 'GET_FACULTYRECEIPT_ERROR'

// VERIFY FACULTY RECEIPT
export const VERIFY_FACULTYRECEIPT_REQUEST = 'VERIFY_FACULTYRECEIPT_REQUEST'
export const VERIFY_FACULTYRECEIPT_SUCCESS = 'VERIFY_FACULTYRECEIPT_SUCCESS'
export const VERIFY_FACULTYRECEIPT_ERROR = 'VERIFY_FACULTYRECEIPT_ERROR'