import "./MetaLoader.css"


const MetaLoader = () => {
    return (
        <div className="spinner col-12 mx-auto"></div>
    )
}

export default MetaLoader
