// DEPARTMENT LOGIN ACTIONS
export const DEPARTMENT_LOGIN_REQUEST = 'DEPARTMENT_LOGIN_REQUEST'
export const DEPARTMENT_LOGIN_SUCCESS = 'DEPARTMENT_LOGIN_SUCCESS'
export const DEPARTMENT_LOGIN_ERROR = 'DEPARTMENT_LOGIN_ERROR'
export const DEPARTMENT_LOGOUT = 'DEPARTMENT_LOGOUT'


// DEPARTMENT REGISTER ACTIONS
export const DEPARTMENT_REGISTER_REQUEST = 'DEPARTMENT_REGISTER_REQUEST'
export const DEPARTMENT_REGISTER_SUCCESS = 'DEPARTMENT_REGISTER_SUCCESS'
export const DEPARTMENT_REGISTER_ERROR = 'DEPARTMENT_REGISTER_ERROR'



// GET DEPARTMENT RECEIPTS
export const GET_DEPARTMENTRECEIPTS_REQUEST = 'GET_DEPARTMENTRECEIPTS_REQUEST'
export const GET_UNVERIFIEDDEPARTMENTRECEIPTS_SUCCESS = 'GET_UNVERIFIEDDEPARTMENTRECEIPTS_SUCCESS'
export const GET_VERIFIEDDEPARTMENTRECEIPTS_SUCCESS = 'GET_VERIFIEDDEPARTMENTRECEIPTS_SUCCESS'
export const GET_DEPARTMENTRECEIPTS_ERROR = 'GET_DEPARTMENTRECEIPTS_ERROR'

// GET DEPARTMENT RECEIPT
export const GET_DEPARTMENTRECEIPT_REQUEST = 'GET_DEPARTMENTRECEIPT_REQUEST'
export const GET_DEPARTMENTRECEIPT_SUCCESS = 'GET_DEPARTMENTRECEIPT_SUCCESS'
export const GET_DEPARTMENTRECEIPT_ERROR = 'GET_DEPARTMENTRECEIPT_ERROR'

// VERIFY DEPARTMENT RECEIPT
export const VERIFY_DEPARTMENTRECEIPT_REQUEST = 'VERIFY_DEPARTMENTRECEIPT_REQUEST'
export const VERIFY_DEPARTMENTRECEIPT_SUCCESS = 'VERIFY_DEPARTMENTRECEIPT_SUCCESS'
export const VERIFY_DEPARTMENTRECEIPT_ERROR = 'VERIFY_DEPARTMENTRECEIPT_ERROR'



export const GET_VERIFIEDSCHOOLRECEIPTS_REQUEST = 'GET_VERIFIEDSCHOOLRECEIPTS_REQUEST'
export const GET_VERIFIEDSCHOOLRECEIPTS_SUCCESS = 'GET_VERIFIEDSCHOOLRECEIPTS_SUCCESS'
export const GET_VERIFIEDSCHOOLRECEIPTS_ERROR = 'GET_VERIFIEDSCHOOLRECEIPTS_ERROR'